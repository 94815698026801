import classNames from 'classnames';
import React from 'react';
import './styles.scss';

type SpinnerProps = {
  className?: string;
  fixed?: boolean;
};

const Spinner = ({className, fixed = false}: SpinnerProps) => (
  <div role="progressbar" className={classNames('spinner', className, {fixed})}/>
);

export default Spinner;
