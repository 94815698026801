import { InfiniteData, useInfiniteQuery, useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import {
   DOCUMENT_STATUS,
   ENDPOINTS,
   NOTIFICATION_TYPE,
   NOTIFICATIONS_SIZE,
   PARTY_TYPES,
   PRODUCER_ACCOUNT_TYPES,
   STEPS,
} from 'shared/constants';
import { useAxios } from 'v2/common/providers/AxiosProvider';
import { queryClient } from 'v2/common/providers/QueryProvider';
import { transformNotificationsResponse } from 'v2/common/utils/apiTransformations';

export enum NotificationSeverity {
   Warn = 'WARN',
   Info = 'INFO',
}

export enum NotificationStatus {
   Read = 'READ',
   Unread = 'UNREAD',
}

export interface INotification {
   id: string;
   type: keyof typeof NOTIFICATION_TYPE;
   recipientId: string;
   offerId: Nullable<number>;
   proposalId: Nullable<number>;
   salesProcessId: number;
   number: string;
   salesProcessState: string;
   salesProcessStep: Nullable<keyof typeof STEPS>;
   notificationStatus: NotificationStatus;
   severity: NotificationSeverity;
   data: {
      recipients: number[];
      policyPartyType?: keyof typeof PARTY_TYPES;
      discardDate?: string;
      status?: keyof typeof DOCUMENT_STATUS;
   };
   created: string;
   modified: Nullable<string>;
   producerAccount: {
      number: number;
      type: keyof typeof PRODUCER_ACCOUNT_TYPES;
      partyId: string;
      roles: string[];
      isActive: boolean;
   };
   policyHolder: {
      firstName: string;
      surname: string;
   };
}

export type Notification = Omit<
   INotification,
   'recipientId' | 'salesProcessId' | 'data' | 'modified' | 'producerAccount' | 'policyHolder'
> & {
   policyPartyType?: keyof typeof PARTY_TYPES;
   discardDate?: string;
   status?: keyof typeof DOCUMENT_STATUS;
   policyHolderName: string;
   producerAccountNumber: string;
   producerAccountType: keyof typeof PRODUCER_ACCOUNT_TYPES;
};

export function useNotifications() {
   const axios = useAxios();

   return useInfiniteQuery(
      'notifications',
      async ({pageParam}) => {
         const {data} = await axios.get<INotification[]>(ENDPOINTS.notifications as string, {params: pageParam});

         return transformNotificationsResponse(data);
      },
      {
         getNextPageParam: (lastPage) => {
            const lastNotification = lastPage[NOTIFICATIONS_SIZE - 1];

            return lastNotification
               ? {toDateTime: lastNotification.created, size: NOTIFICATIONS_SIZE}
               : false;
         },
         staleTime: 1000 * 60 * 10,
         refetchInterval: 1000 * 60 * 10,
         refetchIntervalInBackground: true,
         refetchOnWindowFocus: true,
      }
   );
}

export function setNotificationsFirstPage() {
   queryClient.setQueryData<InfiniteData<INotification[]> | undefined>('notifications', (data) => {
      if (data) {
         return {pages: data.pages.slice(0, 1), pageParams: data.pageParams.slice(0, 1)};
      }
      return data;
   });
}

export function useRemoveNotification(notificationId: string) {
   const axios = useAxios();

   return useMutation(
      'removeNotification',
      () => axios.delete(`${ENDPOINTS.notifications}/${notificationId}`),
      {
         onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite optimistic update)
            await queryClient.cancelQueries('notifications');

            // Snapshot the previous value
            const previousNotifications = queryClient.getQueryData<InfiniteData<INotification[]>>(
               'notifications'
            );

            // Optimistically update to the new value
            queryClient.setQueryData<InfiniteData<INotification[]> | undefined>(
               'notifications',
               (data) => {
                  if (data) {
                     const {pages, pageParams} = data;
                     const newPages = pages.map((page) =>
                        page.filter((notification) => notification.id !== notificationId)
                     );

                     return {pages: newPages, pageParams};
                  }
                  return data;
               }
            );

            // Return a context object with the snapshotted value
            return {previousNotifications};
         },
         // If the mutation fails, use the context returned from onMutate to roll back
         onError: (err, newTodo, context) => {
            queryClient.setQueryData('notifications', context?.previousNotifications);
         },
         // Always refetch after error or success:
         onSettled: () => {
            queryClient.invalidateQueries('notifications');
         },
      }
   );
}

export function useMarkAsRead() {
   const axios = useAxios();

   return useMutation<AxiosResponse<void>, AxiosError, Record<'toDateTime', string>>(
      'markNotificationsAsRead',
      (data) => axios.post(`${ENDPOINTS.notifications}/status`, data),
      {
         onSuccess: () => queryClient.invalidateQueries('notifications'),
      }
   );
}
