import React, {PureComponent} from 'react';
import {bool, func, string, node} from 'prop-types';
import {connect} from 'react-redux';
import {history} from '../../../../history';
import NavButton from '../../../../shared/components/NavButton';
import {handleNavigationButtonClickAction} from '../../reducer';

export class NavigationButton extends PureComponent {
   static propTypes = {
      className: string,
      disabled: bool,
      label: node,
      route: string,
      handleNavigation: func,
      dataTestId: string,
   };

   render() {
      const {disabled, label, className, dataTestId} = this.props;

      return (
         <NavButton
            className={className}
            disabled={disabled}
            onClick={this.handleNavigationButtonClick}
            dataTestId={dataTestId}
         >
            {label}
         </NavButton>
      );
   }

   handleNavigationButtonClick = () => {
      const {handleNavigation, route} = this.props;
      const onActiveFormSuccessfullySaved = () => history.push(route);

      handleNavigation({onFormSubmittedCallback: onActiveFormSuccessfullySaved});
   };
}

export default connect(null, {
   handleNavigation: handleNavigationButtonClickAction.request,
})(NavigationButton);
