import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import LoadingButton, { loadingButtonClasses } from '@mui/lab/LoadingButton';

import { useDownloadDocument } from 'v2/common/api/queries/toolsDocuments';

type DocumentLoadingButtonProps = {
  reference: string;
  label: React.ReactNode;
  name?: string;
  sx?: React.CSSProperties;
};

const DocumentLoadingButton = ({ reference, label, name, sx }: DocumentLoadingButtonProps) => {
  const { refetch, isFetching } = useDownloadDocument({ reference, name });
  const handleDownload = () => {
    refetch({ throwOnError: true, cancelRefetch: true });
  };

  return (
    <LoadingButton
      sx={{
        marginTop: 1,
        [`&.${loadingButtonClasses.loading}`]: {
          color: (theme) => theme.palette.primary.contrastText,
          borderColor: (theme) => theme.palette.primary.contrastText,
        },
        ...sx,
      }}
      loadingPosition="start"
      startIcon={<DownloadRoundedIcon />}
      variant="outlined"
      color="inherit"
      loading={isFetching}
      onClick={handleDownload}
    >
      {label}
    </LoadingButton>
  );
};

export default DocumentLoadingButton;
