import { createSelector } from '@reduxjs/toolkit';
import { formValueSelector, isSubmitting } from 'redux-form';

import { INSURANCE_INTRODUCER } from '../../../shared/constants';
import {
  selectActionData,
  selectAsyncActionData,
  selectIsFinishedStatus,
  selectIsInProgressStatus,
} from '../../../shared/reducers/createReducer';
import { getStateByContainerId } from '../../../shared/reducers/selectors';
import {
  getCurrentState as getFormWrapperState,
  setFormSavingAction,
} from '../../FormWrapper/reducer';

import { containerId, fetchAdditionalInformation, saveAdditionalInformation } from './reducer';
import { PROVIDED_FIELD_NAME } from '.';

export const getCurrentState = getStateByContainerId(containerId);

const selectFormValues = formValueSelector(containerId);

export const selectData = (state) =>
  selectAsyncActionData(getCurrentState(state), fetchAdditionalInformation.type);

export const selectInitialValuesAdditionalInformationForm = createSelector(
  selectData,
  (additionalInformationData) => {
    if (!additionalInformationData) {
      return;
    }

    let preparedAdditionalInformationData = additionalInformationData;

    const defaultInsuranceIntroducer =
      additionalInformationData.insuranceIntroducer || INSURANCE_INTRODUCER.NONE;

    preparedAdditionalInformationData = {
      ...additionalInformationData,
      insuranceIntroducer: defaultInsuranceIntroducer,
    };

    return preparedAdditionalInformationData;
  }
);

export const selectIsFetching = (state) =>
  selectIsInProgressStatus(getCurrentState(state), fetchAdditionalInformation.type);

export const selectIsFetched = (state) =>
  selectIsFinishedStatus(getCurrentState(state), fetchAdditionalInformation.type);

export const selectIsSavingFormInProgress = (state) =>
  selectActionData(getFormWrapperState(state), setFormSavingAction.type);

export const selectIsFormFetching = createSelector(
  [selectIsFetched, selectIsSavingFormInProgress],
  (isFetched, isSavingFormInProgress) => !isFetched && !isSavingFormInProgress
);

export const selectIsSaving = (state) =>
  selectIsInProgressStatus(getCurrentState(state), saveAdditionalInformation.type);

export const selectProvidedFormValue = (state) => selectFormValues(state, PROVIDED_FIELD_NAME);

export const selectIsSubmitting = (state) => isSubmitting(containerId)(state);

export const selectIsSavingButtonDisabled = createSelector(
  [selectIsSaving, selectIsSubmitting],
  (isSaving, isSubmittingStatus) => isSaving || isSubmittingStatus
);
