import Grid from '@mui/material/Grid';

import { Routes, useNavigate } from 'v2/common/hooks/router';
import { useDrawerBlurredBackdrop } from 'v2/features/app-drawer/hooks';
import { usePermissions } from 'v2/features/permissions/hooks';
import { Permission } from 'v2/features/permissions/types';
import { Statistics } from 'v2/pages/Homepage/components/Statistics';
import { useCalculator } from 'v2/pages/Homepage/hooks';

import { Commissions } from './components/Commissions';
import { Greeting } from './components/Greeting';
import { Infoblock } from './components/Infoblock';
import { Portfolio } from './components/Portfolio';
import { StartSalesProcessButton } from './components/StartSalesProcessButton';

import './styles.scss';

export const Homepage = () => {
  useDrawerBlurredBackdrop();
  const navigate = useNavigate();
  // Exact permissions used because of https://delanet.atlassian.net/browse/BROKP-4976
  const hasExactCommissionsPermissions = usePermissions(
    [Permission.ReadCommissions, Permission.CommissionsList],
    'exact'
  );
  const hasPortfolioPermissions = usePermissions(
    [Permission.OfferList, Permission.ProposalList, Permission.PolicyList],
    'some'
  );
  const hasStartSalesProcessPermissions = usePermissions(
    [Permission.CreateOffer, Permission.CreateProposal],
    'some'
  );
  const goToPortfolio = () => navigate(Routes.portfolio);
  const goToCommissions = () => navigate(Routes.commissions);
  const goToCalculator = useCalculator();

  return (
    <div className="homepage" data-testid="home-page">
      <Grid container columnSpacing={2}>
        <Grid item container md={12} lg={9}>
          <Grid item xs={12}>
            <Greeting />
          </Grid>
          {hasExactCommissionsPermissions && (
            <Grid item xs={12}>
              <Commissions goToCommissions={goToCommissions} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Statistics />
          </Grid>
          {hasPortfolioPermissions && (
            <Grid item xs={12} zeroMinWidth>
              <Portfolio goToCalculator={goToCalculator} goToPortfolio={goToPortfolio} />
            </Grid>
          )}
        </Grid>
        <Grid item container md={12} lg={3} flexDirection="column">
          {hasStartSalesProcessPermissions && (
            <Grid item>
              <StartSalesProcessButton goToCalculator={goToCalculator} />
            </Grid>
          )}
          <Grid item>
            <Infoblock />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
