import classNames from 'classnames';
import {
   arrayOf,
   bool,
   instanceOf,
   number,
   oneOfType,
   func,
   object,
   string,
   element,
} from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import Spinner from '../../Spinner';
import classes from './styles.module.scss';
import FormControl from '../FormControl';

const TextInputControl = (props) => {
   return (
      <FormControl {...props}>
         <TextInput />
      </FormControl>
   );
};

export const TextInput = ({
   id,
   name,
   disabled = false,
   loading = false,
   hasError = false,
   inputRef,
   mask,
   prefix,
   postfix,
   placeholder,
   iconClassName,
   className,
   value,
   onBlur,
   onChange,
   onFocus,
   maxLength,
   dataTestIdPrefix = '',
}) => {
   const _onBlur = () => {
      const val = value !== '' ? value.toString().trim().replace(/\s+/g, ' ') : null;
      onBlur && onBlur(val);
   };
   const inputElementProps = {
      id,
      name,
      maxLength,
      type: 'text',
      autoComplete: 'off',
      className: classNames(
         classes.textInput,
         {
            [classes.hasError]: hasError,
            [classes.icon]: iconClassName,
            [classes.loading]: loading,
         },
         className
      ),
      ref: inputRef,
      placeholder: disabled ? undefined : placeholder,
      disabled,
      value,
      onChange,
      onBlur: _onBlur,
      onFocus,
      'data-testid': `${dataTestIdPrefix}-input`.toLowerCase()
};
   let inputElement = <input {...inputElementProps} />;

   if (mask) {
      inputElement = (
         <MaskedInput mask={mask} guide placeholderChar={'\u2000'} {...inputElementProps} />
      );
   }

   return (
      <div className={classes.textInputWrapper}>
         {prefix}
         {iconClassName && <i className={classNames('iconfont', iconClassName)} />}
         {inputElement}
         {loading && <Spinner className={classes.spinner} />}
         {postfix}
      </div>
   );
};

TextInput.propTypes = {
   id: string,
   name: string,
   disabled: bool,
   loading: bool,
   hasError: bool,
   mask: oneOfType([arrayOf(oneOfType([string, instanceOf(RegExp)])), func, object]),
   prefix: oneOfType([string, element]),
   postfix: oneOfType([string, element]),
   placeholder: string,
   iconClassName: string,
   className: string,
   value: oneOfType([string, number]),
   onBlur: func,
   onChange: func,
   onFocus: func,
   maxLength: number,
   dataTestIdPrefix: string,
};

export default TextInputControl;
