// Place application wide constants here
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD/MM/YYYY HH:mm:SS Z';

export const POLLING_INTERVAL = 2.5 * 1000;
export const DFF_POLLING_INTERVAL = 60 * 1000;
export const POLLING_MAX_RETRY = 15;
export const PAGE_SIZE = 20;
export const AK_CODE_SORT_PARAMETER = 'AK_CODE';
export const NOTIFICATIONS_SIZE = 10;
export const NOTIFICATION_ITEMS_VIEWPORT_COUNT = 5;
export const NOTIFICATIONS_MIN_HEIGHT = 95;
export const NOTIFICATIONS_MAX_HEIGHT = 445;

export const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/;

export const ROOT_URI =
  window.location.origin ||
  `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;

export const API_URL = process.env.REACT_APP_API_URL ?? '/gateway';

export const API_VERSION = {
  v1: '/api/dc/v1',
  v2: '/api/dc/v2',
};

export const ENDPOINTS = {
  premiumCalculation: `${API_VERSION.v1}/premium/calculation`,
  b2cVerify: `${API_VERSION.v1}/b2c-user-login`,
  agentNumbers: `${API_VERSION.v1}/agent-numbers`,
  offers: `${API_VERSION.v1}/offers`,
  proposals: `${API_VERSION.v1}/proposals`,
  policies: `${API_VERSION.v1}/policies`,
  salesProcesses: `${API_VERSION.v1}/sales-processes`,
  healthDeclaration: `${API_VERSION.v1}/health-declaration`,
  policyUpdates: `${API_VERSION.v1}/templates`,
  commissions: `${API_VERSION.v1}/commissions`,
  notifications: `${API_VERSION.v1}/notifications`,
  bankService: `${API_VERSION.v1}/bank-service`,
  address: `${API_VERSION.v2}/address`,
  doNotCallMeList: `${API_VERSION.v1}/ds-enhancement/dncm`,
  cooperativeMembership: `${API_VERSION.v1}/cooperative-membership`,
};

export const STEPS = {
  OFFER: 'offer',
  PROPOSAL: 'proposal',
  NEEDS_AND_DESIRES: 'needs-and-desires',
  POLICY_HOLDER: 'policy-holder',
  INSURED: 'insured',
  BENEFICIARIES: 'beneficiaries',
  DOCUMENTS: 'documents',
  HEALTH_DECLARATION: 'health-declaration',
  ADDITIONAL_INFORMATION: 'additional-information',
};

export const PARTY_TYPES = {
  FIRST_INSURED_ADULT: 'FIRST_INSURED_ADULT',
  SECOND_INSURED_ADULT: 'SECOND_INSURED_ADULT',
  ABSENT_INSURED: 'ABSENT_INSURED',
  POLICY_HOLDER: 'POLICY_HOLDER',
  INSURED_CHILD: 'INSURED_CHILD',
  BENEFICIARY: 'BENEFICIARY',
};

export const SECTIONS = {
  FIRST_INSURED_ADULT: 'firstInsuredAdult',
  SECOND_INSURED_ADULT: 'secondInsuredAdult',
  HEALTH_DECLARATION: 'healthDeclaration',
};

export const HEALTH_DECLARATION_FORM_STATES = {
  BASIC: 'BASIC',
  DETAILED: 'DETAILED',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  COMPLETED: 'COMPLETED',
};

export const HEALTH_DECLARATION_SHARE_STATUS = {
  SHARED: 'SHARED',
  IN_PROCESS: 'IN_PROCESS',
  COMPLETED: 'COMPLETED',
};

export const PRODUCER_ACCOUNT_TYPES = {
  OWN: 'Own',
  SIAM: 'SIAM',
  LEADGENERATOR: 'Lead Generator',
  ONLINE: 'Online - Chat',
};

export const AUTH_STORAGE_KEY = 'agentNumber';

export const LOCALES = {
  EN: 'en-US',
  NL: 'nl-BE',
  FR: 'fr-FR',
  ID: 'en-ID',
};

export const LANGUAGES = {
  FRENCH: 'FRENCH',
  DUTCH: 'DUTCH',
};

export const LANGUAGE_TO_LOCALE_MAP = {
  [LANGUAGES.DUTCH]: LOCALES.NL,
  [LANGUAGES.FRENCH]: LOCALES.FR,
};

export const LOCALES_TO_BROWSER_LOCALE_MAP = {
  [LOCALES.EN]: 'en',
  [LOCALES.FR]: 'fr',
  [LOCALES.NL]: 'nl',
};

export const DOCUMENT_SIGN_STATUS = {
  SUCCESSFUL: 'SUCCESSFUL',
  REJECTED: 'REJECTED',
};

export const DOCUMENT_STATUS = {
  SIGNED: 'SIGNED',
  NOT_SIGNED: 'NOT_SIGNED',
  UPLOADED: 'UPLOADED',
  REJECTED: 'REJECTED',
  WAITING: 'WAITING',
  FAILED: 'FAILED',
  UNAVAILABLE: 'UNAVAILABLE',
};

export const PROCESS_STATUS = {
  DRAFT: 'DRAFT',
  SHARED: 'SHARED',
  WAITING_FOR_IDENTITY_CONFIRMATION: 'WAITING_FOR_IDENTITY_CONFIRMATION',
  WAITING_FOR_HEALTH_DECLARATION: 'WAITING_FOR_HEALTH_DECLARATION',
  DOCUMENTS_REQUESTED: 'DOCUMENTS_REQUESTED',
  DOCUMENTS_GENERATING: 'DOCUMENTS_GENERATING',
  DOCUMENTS_GENERATED: 'DOCUMENTS_GENERATED',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  INVALID: 'INVALID',
  WAITING_FOR_SIGNATURE: 'WAITING_FOR_SIGNATURE',
  SIGNING_POSTPONED: 'SIGNING_POSTPONED',
  SIGNED: 'SIGNED',
  SIGNING_FAILED: 'SIGNING_FAILED',
  REJECTED_BY_POLICY_PARTY: 'REJECTED_BY_POLICY_PARTY',
  SENDING_TO_DELA: 'SENDING_TO_DELA',
  IN_REVIEW_BY_DELA: 'IN_REVIEW_BY_DELA',
  REVIEW_BY_DELA_FAILED: 'REVIEW_BY_DELA_FAILED',
  ACTIVE: 'ACTIVE',
  ENDED: 'ENDED',
  CANCELLED: 'CANCELLED',
  FIRST_ENTRY: 'FIRST_ENTRY',
  SURRENDER_POLICY: 'SURRENDER_POLICY',
  VALID: 'VALID',
  MUTATION_TO_ANOTHER_POLICY: 'MUTATION_TO_ANOTHER_POLICY',
  PASSED_AWAY: 'PASSED_AWAY',
  POLICY_WITHOUT_EFFECT: 'POLICY_WITHOUT_EFFECT',
  REDUCTION_POLICY: 'REDUCTION_POLICY',
  POLICY_EXPIRES_AFTER_REDUCTION: 'POLICY_EXPIRES_AFTER_REDUCTION',
  SUBMISSION_POSTPONED: 'SUBMISSION_POSTPONED',
  SUBMISSION_FAILED: 'SUBMISSION_FAILED',
  UNKNOWN: 'UNKNOWN',
};

export const DOCUMENT_GENERATION_POLLING_STATUSES = {
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
  POSTPONED: 'POSTPONED',
  FAILED: 'FAILED',
};

export const CONFIRMATION_TYPES = {
  SIGN_WITH_QUALIFIED_ELECTRONIC_SIGNATURE: 'SIGN_WITH_QUALIFIED_ELECTRONIC_SIGNATURE',
  UPLOAD_COPY_OF_ID_CARD: 'UPLOAD_COPY_OF_ID_CARD',
};

export const PAYMENT_FREQUENCY = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  HALF_YEARLY: 'HALF_YEARLY',
  YEARLY: 'YEARLY',
  ONE_TIME_ONLY: 'ONE_TIME_ONLY',
};

export const SIMPLE_ANSWER = {
  YES: 'YES',
  NO: 'NO',
};

export const SMOKING_ANSWER = {
  YES: 'CURRENTLY_SMOKING',
  QUIT: 'USED_TO_SMOKE',
  NO: 'NEVER_SMOKED',
};

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const INSURANCE_INTRODUCER = {
  NONE: 'NONE',
  GEZINSBOND: 'GEZINSBOND',
};

export const ACTION_STATUS = {
  INITIAL: 'initial',
  IN_PROGRESS: 'inProgress',
  SUCCESS: 'success',
  FAILURE: 'failure',
  UPDATE: 'update',
};

export const LEGAL_INFO_LINKS = {
  [LOCALES.EN]:
    'https://www.dela.be/nl/juridische-informatie/documentatie-uitvaartzorgplan/verzekeringen-vergelijken',
  [LOCALES.NL]:
    'https://www.dela.be/nl/juridische-informatie/documentatie-uitvaartzorgplan/verzekeringen-vergelijken',
  [LOCALES.FR]:
    'https://www.dela.be/fr/mentions-legales/documentation-plan-de-prevoyance-obseques/comparer-des-assurances',
};

export const NEEDS_AND_DESIRES_TYPE = {
  DENT: 'DENT',
  EXTERNAL: 'EXTERNAL',
};

export const MAIL_INFO = {
  ADDRESS: 'salessupport@dela.be',
  PHONE: '+32 2 800 87 88',
};

export const NOTIFICATION_STATUS = {
  READ: 'READ',
  UNREAD: 'UNREAD',
};

export const POLICY_PRODUCT_NAME = {
  HR_400: 'HR_400',
  HR_420: 'HR_420',
  HR_435: 'HR_435',
  PROD_EX_LILAS: 'PROD_EX_LILAS',
  UZP: 'UZP',
  UZP_PLUS: 'UZP+',
  UZPN: 'UZPN',
};

export const POLICY_DOCUMENT = {
  SPECIFIC_TERMS_CONDITIONS: 'SPECIFIC_TERMS_CONDITIONS',
  ADAPTED_PROPOSAL: 'ADAPTED_PROPOSAL',
  SEPA_MANDATE: 'SEPA_MANDATE',
  YEARLY_INFORMATION: 'YEARLY_INFORMATION',
  ANALYSIS_NEEDS_DESIRES: 'ANALYSIS_NEEDS_DESIRES',
  PROPOSAL: 'PROPOSAL',
  BENEFICIARY: 'BENEFICIARY',
  PAYMENT_FIRST_PREMIUM: 'PAYMENT_FIRST_PREMIUM',
  ENDORSEMENT: 'ENDORSEMENT',
};

export const WARRANTY_END_REASON = {
  DECREASE: 'DECREASE',
  REDUCTION: 'REDUCTION',
  SURRENDER: 'SURRENDER',
  SPLIT: 'SPLIT',
  MERGE: 'MERGE',
  DEATH: 'DEATH',
  CANCELLATION: 'CANCELLATION',
};

export const COVERAGE_STATUS = {
  ENDED: 'ENDED',
  ACTIVE: 'ACTIVE',
  MERGED: 'MERGED',
  REDUCED: 'REDUCED',
  CANCELLED: 'CANCELLED',
};

export const EMAIL_DELIVERY_STATUS = {
  UNDELIVERED: 'UNDELIVERED',
  DELIVERED: 'DELIVERED',
  PENDING: 'PENDING',
};

export const COMMISSION_TYPES = {
  receipt: 'BALANCE_SHEET',
  statement: 'STATEMENT',
};

export const PRODUCT_VERSIONS = {
  V_2019_10: 'V_2019_10',
  V_2020_01: 'V_2020_01',
};

export const QUESTIONNAIRE_STEP_TYPES = {
  GENERIC: 'GENERIC',
  SPECIFIC: 'SPECIFIC',
};

export const SIGNING_METHODS = {
  WITH_INTERMEDIARY: 'WITH_INTERMEDIARY',
  REMOTELY: 'REMOTELY',
  DEFAULT: 'DEFAULT',
};

export const NOTIFICATION_TYPE = {
  PROPOSAL_IS_WAITING_FOR_SIGNATURE: 'PROPOSAL_IS_WAITING_FOR_SIGNATURE',
  REMOTE_HEALTH_DECLARATION_COMPLETED: 'REMOTE_HEALTH_DECLARATION_COMPLETED',
  SHARE_OFFER_EMAIL_UNDELIVERED: 'SHARE_OFFER_EMAIL_UNDELIVERED',
  PROPOSAL_WILL_BE_DISCARDED: 'PROPOSAL_WILL_BE_DISCARDED',
  DOCUMENTS_WERE_SIGNED_BY_POLICY_PARTY: 'DOCUMENTS_WERE_SIGNED_BY_POLICY_PARTY',
  DOCUMENTS_WERE_REJECTED_BY_POLICY_PARTY: 'DOCUMENTS_WERE_REJECTED_BY_POLICY_PARTY',
};

export const SIGNING_ERRORS = {
  PROCESSING_STATUS_POSTPONED: 'PROCESSING_STATUS_POSTPONED',
  CURRENT_SIGNER_RESET_REQUIRED: 'CURRENT_SIGNER_RESET_REQUIRED',
};

export const STATUS_CLASSNAME = {
  DRAFT: 'draft',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in-progress',
  CANCELLED: 'cancelled',
};

export const PROCESS_STATUS_CLASSNAME = {
  [PROCESS_STATUS.DRAFT]: STATUS_CLASSNAME.DRAFT,
  [PROCESS_STATUS.VALIDATION_FAILED]: STATUS_CLASSNAME.DRAFT,
  [PROCESS_STATUS.UNKNOWN]: STATUS_CLASSNAME.DRAFT,
  [PROCESS_STATUS.ACTIVE]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.SHARED]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.IN_REVIEW_BY_DELA]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.REVIEW_BY_DELA_FAILED]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.SIGNED]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.FIRST_ENTRY]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.VALID]: STATUS_CLASSNAME.COMPLETED,
  [PROCESS_STATUS.REDUCTION_POLICY]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.INVALID]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.DOCUMENTS_REQUESTED]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.DOCUMENTS_GENERATING]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.DOCUMENTS_GENERATED]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.WAITING_FOR_HEALTH_DECLARATION]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.WAITING_FOR_IDENTITY_CONFIRMATION]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.WAITING_FOR_SIGNATURE]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.SIGNING_POSTPONED]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.SENDING_TO_DELA]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.SIGNING_FAILED]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.SUBMISSION_POSTPONED]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.SUBMISSION_FAILED]: STATUS_CLASSNAME.IN_PROGRESS,
  [PROCESS_STATUS.CANCELLED]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.REJECTED_BY_POLICY_PARTY]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.POLICY_EXPIRES_AFTER_REDUCTION]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.MUTATION_TO_ANOTHER_POLICY]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.SURRENDER_POLICY]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.POLICY_WITHOUT_EFFECT]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.PASSED_AWAY]: STATUS_CLASSNAME.CANCELLED,
  [PROCESS_STATUS.ENDED]: STATUS_CLASSNAME.CANCELLED,
};

export const PHONE_CODES = {
  BELGIUM: '+32',
  FRANCE: '+33',
  GERMANY: '+49',
  LUXEMBOURG: '+352',
  NETHERLANDS: '+31',
};

export const phoneValueToCountry = {
  [PHONE_CODES.BELGIUM]: 'Belgium',
  [PHONE_CODES.FRANCE]: 'France',
  [PHONE_CODES.GERMANY]: 'Germany',
  [PHONE_CODES.LUXEMBOURG]: 'Luxembourg',
  [PHONE_CODES.NETHERLANDS]: 'Netherlands',
};

export const phoneValueToCountryCodes = {
  [PHONE_CODES.BELGIUM]: 'BE',
  [PHONE_CODES.FRANCE]: 'FR',
  [PHONE_CODES.GERMANY]: 'DE',
  [PHONE_CODES.LUXEMBOURG]: 'LU',
  [PHONE_CODES.NETHERLANDS]: 'NL',
};

export const COMPLETENESS_STATUSES = {
  DONE: 'DONE',
  NOT_DONE: 'NOT_DONE',
  HALF_DONE: 'HALF_DONE',
  DISABLED: 'DISABLED',
  REJECTED: 'REJECTED',
};

export const PERSONAL_INFO_KEYS = {
  FIRST_NAME: 'firstName',
  SURNAME: 'surname',
  BIRTH_DATE: 'birthDate',
  GENDER: 'gender',
  NATIONAL_REGISTER_NUMBER: 'nationalRegisterNumber',
};

export const SERVER_ERROR_TYPES = {
  VALIDATION_ERRORS: 'validationErrors',
  DUPLICATED_INSURED_ADULTS: 'duplicatedInsuredAdults',
  DUPLICATED_INSURED_CHILDREN: 'duplicatedChildren',
  INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES: 'insuredAdultsAssignedAsBeneficiaries',
  DUPLICATED_BENEFICIARIES: 'duplicatedBeneficiaries',
  INVALID_RESIDENTIAL_ADDRESS: 'invalidResidentialAddress',
};

export const HANDLED_SERVER_ERRORS = [
  SERVER_ERROR_TYPES.VALIDATION_ERRORS,
  SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS,
  SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN,
  SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES,
  SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES,
];

export const DUPLICATION_SERVER_ERRORS = [
  SERVER_ERROR_TYPES.DUPLICATED_INSURED_ADULTS,
  SERVER_ERROR_TYPES.DUPLICATED_INSURED_CHILDREN,
  SERVER_ERROR_TYPES.INSURED_ADULTS_ASSIGNED_AS_BENEFICIARIES,
  SERVER_ERROR_TYPES.DUPLICATED_BENEFICIARIES,
];

export const PAYMENT_METHODS = {
  SEPA_MANDATE: 'SEPA_MANDATE',
  BANK_TRANSFER: 'BANK_TRANSFER',
};

export const DOCUMENT_STATUSES_CLASS_NAME = {
  [DOCUMENT_STATUS.SIGNED]: 'signed',
  [DOCUMENT_STATUS.NOT_SIGNED]: 'not-signed',
  [DOCUMENT_STATUS.UPLOADED]: 'uploaded',
  [DOCUMENT_STATUS.REJECTED]: 'rejected',
  [DOCUMENT_STATUS.WAITING]: 'waiting',
  [DOCUMENT_STATUS.UNAVAILABLE]: 'unavailable',
  [DOCUMENT_STATUS.FAILED]: 'failed',
};

export const DOCUMENT_STATUSES_WITH_ICONS = [
  DOCUMENT_STATUS.SIGNED,
  DOCUMENT_STATUS.UPLOADED,
  DOCUMENT_STATUS.REJECTED,
];

export const DOCUMENT_STATUSES_ICONS = {
  [DOCUMENT_STATUS.SIGNED]: 'icon-check',
  [DOCUMENT_STATUS.UPLOADED]: 'icon-check',
  [DOCUMENT_STATUS.REJECTED]: 'icon-cross',
};

export const DOCUMENTS_POPOVER_ID = 'Documents';

export const PARTY_TYPES_TO_MESSAGES_ID = {
  [PARTY_TYPES.POLICY_HOLDER]: 'misc.policyHolder',
  [PARTY_TYPES.FIRST_INSURED_ADULT]: 'misc.insuredAdult',
  [PARTY_TYPES.SECOND_INSURED_ADULT]: 'misc.insuredAdult',
};

export const INSURED_PRODUCT_BASIS_TYPES = {
  COLLABORATION_WITH_CERTAIN_INSURANCE_COMPANIES: 'COLLABORATION_WITH_CERTAIN_INSURANCE_COMPANIES',
  PREVIOUS_ANALYSIS_REGARDING_LIFE_INSURANCE: 'PREVIOUS_ANALYSIS_REGARDING_LIFE_INSURANCE',
  PREVIOUS_ANALYSIS_AND_ANALYSIS_OF_PRODUCTS_ON_MARKET:
    'PREVIOUS_ANALYSIS_AND_ANALYSIS_OF_PRODUCTS_ON_MARKET',
};

export const SOURCE_OT_INFORMATION_TYPES = {
  BROKER_WEBSITE: 'BROKER_WEBSITE',
  ATTACHED_DOCUMENT: 'ATTACHED_DOCUMENT',
};

// ' - . cannot be start and end of the string
// one special symbol cannot be followed by another special symbol
export const specialSymbolsRegexpFirstname =
  /^['.-]+.*$|^.*['.-]+$|^.+(['.-])\1+|(\.'|\.-|'\.|'-|-\.|-').+$/u; // NOSONAR

export const specialSymbolsRegexpSurname =
  /^[.-]+.*$|^'{2,}|^.*['.-]+$|^.+(['.-])\1+|(\.'|\.-|'\.|'-|-\.|-').+$/u; // NOSONAR

// ensure no presence of any non letter symbols except ' - .
export const suspiciousSymbolsRegexp = /^.*[^\p{L}\s\-.'].*$/u;
