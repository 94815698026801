import classNames from 'classnames';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';

import FormControl from '../FormControl';
import RadioButton from '../RadioButton';

import classes from './styles.module.scss';

const RadioButtonGroupControl = (props) => (
  <FormControl {...props}>
    <RadioButtonGroup />
  </FormControl>
);

export const RadioButtonGroup = ({
  id,
  name,
  disabled = false,
  hasError = false,
  options = [],
  prefixId = 'rg',
  vertical = false,
  value,
  onBlur,
  onChange,
  onFocus,
  className,
  dataTestIdPrefix = '',
}) => {
  const _id = id ?? name;
  const inputElementProps = {
    value,
    type: 'radio',
    onChange,
    onBlur,
    onFocus,
  };

  return (
    <div className={classNames(classes.root, className)}>
      <div
        className={classNames(classes.options, {
          [classes.vertical]: vertical,
        })}
      >
        {options.map((option) => {
          const optionId = `${prefixId}-${_id}-${option.value}`;

          return (
            <RadioButton
              key={option.value}
              id={optionId}
              label={option.label}
              name={name}
              value={option.value}
              input={inputElementProps}
              disabled={disabled || option.disabled}
              checked={option.value === value}
              hasError={hasError}
              dataTestId={`${dataTestIdPrefix}-${option.value}-radio`.toLowerCase()}
            />
          );
        })}
      </div>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  id: string,
  name: string,
  disabled: bool,
  hasError: bool,
  options: arrayOf(
    shape({
      value: oneOfType([string, number]),
      label: string,
    })
  ),
  prefixId: string,
  vertical: bool,
  value: oneOfType([string, number]),
  onBlur: func,
  onChange: func,
  onFocus: func,
  className: string,
  dataTestIdPrefix: string,
};

export default RadioButtonGroupControl;
