import {bool, func} from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ActionButton from '../../../../shared/components/ActionButton';
import {FormattedMessage} from '../../../../shared/components/FormattedMessage';
import HowItWorksButton from '../../../../shared/components/HowItWorksButton';
import {selectConvertingToOfferStatus} from '../../../ProposalDetails/selectors';
import {handleSubmitButtonClickAction} from '../../reducer';
import {
   selectIsDisabled,
   selectRequestingDocumentsStateStatus,
   selectIsPolicyHolderForm,
} from '../../selectors';
import SubmitButtonPopover, {SUBMIT_BUTTON_POPOVER_ID} from '../SubmitButtonPopover';
import {selectIsShowSubmitProposalButton, selectProposalDetailsIsFetching} from './selectors';

export class SubmitButton extends PureComponent {
   static propTypes = {
      convertingToOffer: bool,
      disabled: bool,
      handleRequestDocuments: func,
      isPolicyHolderForm: bool,
      proposalDetailsIsFetching: bool,
      requesting: bool,
      showSubmitButton: bool,
   };

   render() {
      const {
         convertingToOffer,
         disabled,
         isPolicyHolderForm,
         proposalDetailsIsFetching,
         requesting,
         showSubmitButton,
      } = this.props;
      const submitButtonDisabled =
         disabled || requesting || convertingToOffer || proposalDetailsIsFetching;

      if (!showSubmitButton) {
         return null;
      }

      return (
         <>
            <SubmitButtonPopover>
               <ActionButton
                  className="action"
                  action={requesting}
                  disabled={submitButtonDisabled}
                  onClick={this.handleClickButton}
                  data-testid="submit-proposal-button"
               >
                  <FormattedMessage id="formWrapper.submitProposal" />
               </ActionButton>
            </SubmitButtonPopover>
            {isPolicyHolderForm && <HowItWorksButton popoverId={SUBMIT_BUTTON_POPOVER_ID} />}
         </>
      );
   }

   handleClickButton = (e) => {
      e.persist();
      this.props.handleRequestDocuments(e);
   };
}

const mapStateToProps = () => {
   return (state) => ({
      convertingToOffer: selectConvertingToOfferStatus(state),
      disabled: selectIsDisabled(state),
      isPolicyHolderForm: selectIsPolicyHolderForm(state),
      proposalDetailsIsFetching: selectProposalDetailsIsFetching(state),
      requesting: selectRequestingDocumentsStateStatus(state),
      showSubmitButton: selectIsShowSubmitProposalButton(state),
   });
};

export default connect(mapStateToProps, {
   handleRequestDocuments: handleSubmitButtonClickAction.request,
})(SubmitButton);
