import {bool, oneOfType, shape, string} from 'prop-types';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ButtonsPanel from '../../../shared/components/ButtonsPanel';
import {OfferMetadataShape, ProposalMetadataShape} from '../../../shared/constants/propTypes';
import {FormattedMessage} from '../../../shared/components/FormattedMessage';
import {
   selectIsFormAsyncValidating,
   selectIsFormSaving,
   selectIsOfferDetailsForm,
   selectMetadata,
   selectRoutes,
   selectShowGoToProposalButton,
} from '../selectors';
import NavigationButton from './NavigationButton';
import SavingButton from './SavingButton';

export class FormWrapperButtonsPanel extends PureComponent {
   static propTypes = {
      showGoToProposalButton: bool,
      hideProposalButtons: bool,
      isSaving: bool,
      metadata: oneOfType([ProposalMetadataShape, OfferMetadataShape]),
      isAsyncValidating: bool,
      routes: shape({prev: string, next: string}),
   };

   render() {
      return (
         <ButtonsPanel
            actions={<SavingButton />}
            navigations={
               <>
                  {this.renderGoToProposalButton()}
                  {this.renderNavigationButtons()}
               </>
            }
         />
      );
   }

   renderGoToProposalButton = () => {
      const {showGoToProposalButton, isSaving, metadata} = this.props;
      if (!showGoToProposalButton) {
         return null;
      }

      return (
         <NavigationButton
            className="next"
            disabled={isSaving}
            route={`/sales-process/proposals/${metadata.proposalId}`}
            label={<FormattedMessage id="formWrapper.goToProposal" />}
         />
      );
   };

   renderNavigationButtons = () => {
      const {isSaving, isAsyncValidating, routes, hideProposalButtons} = this.props;
      if (hideProposalButtons) {
         return null;
      }
      const {prev, next} = routes;
      const messageId = prev && prev.startsWith('/sales-process/offer/') ? 'backToOffer' : 'back';

      return (
         <>
            {prev && (
               <NavigationButton
                  disabled={isSaving || isAsyncValidating}
                  route={prev}
                  label={<FormattedMessage id={`formWrapper.${messageId}`} />}
               />
            )}
            <NavigationButton
               className="next"
               disabled={!next || isSaving || isAsyncValidating}
               route={next}
               label={<FormattedMessage id="formWrapper.nextSection" />}
               dataTestId="next-section-button"
            />
         </>
      );
   };
}

const mapStateToProps = (state) => ({
   showGoToProposalButton: selectShowGoToProposalButton(state),
   hideProposalButtons: selectIsOfferDetailsForm(state),
   metadata: selectMetadata(state),
   isSaving: selectIsFormSaving(state),
   isAsyncValidating: selectIsFormAsyncValidating(state),
   routes: selectRoutes(state),
});

export default connect(mapStateToProps)(FormWrapperButtonsPanel);
