import { useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { RESIDENTIAL_ADDRESS_SAVE_ERROR } from '../../../containers/ProposalDetails/PolicyHolder/sagas';
import { SERVER_ERROR_TYPES } from '../../constants';
import * as fields from '../../constants/fields';
import { required } from '../../services/validators';
import DropdownControl from '../reduxFormField/Dropdown';
import FieldWithServerError from '../reduxFormField/FieldWithServerError';
import TextInputControl from '../reduxFormField/TextInput';

import './styles.scss';

const ResidentialAddressFields = (props) => {
  const {
    disabled = false,
    isOffer = false,
    isPostalCodeValid,
    municipalities,
    isFetchingMunicipalities,
    selectedMunicipality,
    clearMunicipalities,
    fetchStreets,
    isMunicipalityDisabled,
    isStreetNameDisabled,
    isHouseNumberDisabled,
    touch,
    untouch,
    dispatch,
    change,
    hasResidentialAddressSaveError,
    hasResidentialAddressServerError = false,
    clearResidentialAddressServerError = noop,
  } = props;

  const intl = useIntl();

  const onStreetNameSearch = (searchQuery, cb) => {
    if (!searchQuery || isEmpty(municipalities)) {
      return cb([]);
    }

    if (selectedMunicipality && isPostalCodeValid && !isEmpty(municipalities)) {
      const { id: municipalityId } = municipalities.find(
        ({ value }) => value === selectedMunicipality
      );

      return municipalityId
        ? dispatch(
            fetchStreets.action({
              searchQuery,
              municipalityId,
              cb,
              change,
            })
          )
        : cb([]);
    }
  };

  const changeFields = (fieldsToChange) =>
    fieldsToChange.forEach((field) => change(`policyHolder.residentialAddress.${field}`, null));

  const touchFields = (fieldsToTouch) =>
    fieldsToTouch.forEach((field) => touch(`policyHolder.residentialAddress.${field}`));

  const untouchFields = (fieldstoUntouch) =>
    fieldstoUntouch.forEach((field) => untouch(`policyHolder.residentialAddress.${field}`));

  const onPostalCodeChange = () => {
    clearResidentialAddressServerError();
    changeFields(['municipality', 'streetName', 'houseNumber', 'boxNumber']);
    if (isOffer) {
      touchFields(['municipality', 'streetName', 'houseNumber']);
    } else {
      untouchFields(['municipality', 'streetName', 'houseNumber']);
    }
    if (!isEmpty(municipalities)) {
      clearMunicipalities();
    }
  };

  const onMunicipalityChange = () => {
    clearResidentialAddressServerError();
    changeFields(['streetName', 'houseNumber', 'boxNumber']);
    if (isOffer) {
      touchFields(['streetName', 'houseNumber']);
    } else {
      untouchFields(['streetName', 'houseNumber']);
    }
  };

  const onStreetNameChange = () => {
    changeFields(['houseNumber', 'boxNumber']);
    if (isOffer) {
      touchFields(['houseNumber']);
    } else {
      untouchFields(['houseNumber']);
    }
  };

  const onHouseNumberChange = () => {
    changeFields(['boxNumber']);
  };

  const validatePostalCode = isOffer
    ? [...fields.postalCode.validate, required]
    : [...fields.postalCode.validate, ...fields.residentialAddress.validate];

  const placeholder = intl.formatMessage({
    id: isFetchingMunicipalities ? 'table.loadingText' : 'fields.municipality.placeholder',
  });

  const residentialAddressServerError = hasResidentialAddressServerError
    ? {
        path: RESIDENTIAL_ADDRESS_SAVE_ERROR,
        messageProps: {
          id: 'validate.invalidResidentialAddress',
          type: SERVER_ERROR_TYPES.INVALID_RESIDENTIAL_ADDRESS,
        },
      }
    : {
        path: 'policyHolder.residentialAddress.streetName',
      };

  return (
    <Grid
      container
      columnSpacing={3}
      className={classNames({
        'residential-address-invalid':
          hasResidentialAddressServerError || hasResidentialAddressSaveError,
      })}
    >
      <Grid item xs={3}>
        <FieldWithServerError
          name="policyHolder.residentialAddress.postalCode"
          label={intl.formatMessage({
            id: 'fields.postalCode.label',
          })}
          required={isOffer}
          disabled={disabled}
          component={TextInputControl}
          {...fields.postalCode}
          validate={validatePostalCode}
          serverError={{
            path: 'policyHolder.residentialAddress.postalCode',
          }}
          hideLoading
          onChange={onPostalCodeChange}
          dataTestIdPrefix="postal-code"
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWithServerError
          name="policyHolder.residentialAddress.municipality"
          label={intl.formatMessage({
            id: 'fields.municipality.label',
          })}
          placeholder={placeholder}
          required={isOffer}
          disabled={isMunicipalityDisabled}
          component={disabled ? TextInputControl : DropdownControl}
          options={municipalities || []}
          isLoading={isFetchingMunicipalities}
          validate={isOffer ? required : fields.residentialAddress.validate}
          serverError={{
            path: 'policyHolder.residentialAddress.municipality',
          }}
          onChange={onMunicipalityChange}
          {...fields.municipality}
          dataTestIdPrefix="municipality"
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWithServerError
          name="policyHolder.residentialAddress.streetName"
          label={intl.formatMessage({
            id: 'fields.street.label',
          })}
          required={isOffer}
          disabled={isStreetNameDisabled}
          component={isStreetNameDisabled ? TextInputControl : DropdownControl}
          validate={isOffer ? required : fields.residentialAddress.validate}
          serverError={residentialAddressServerError}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: null,
          }}
          loadOptions={onStreetNameSearch}
          isAsync
          isSearchable
          {...fields.streetName}
          onChange={onStreetNameChange}
          dataTestIdPrefix="street-name"
        />
      </Grid>
      <Grid item xs={3}>
        <FieldWithServerError
          name="policyHolder.residentialAddress.houseNumber"
          label={intl.formatMessage({
            id: 'fields.houseNumber.label',
          })}
          required={isOffer}
          disabled={isHouseNumberDisabled}
          component={TextInputControl}
          {...fields.houseNumber}
          validate={
            isOffer
              ? fields.residentialAddress.validateOfferHouseNumber
              : fields.residentialAddress.validateProposalHouseNumber
          }
          serverError={{
            path: 'policyHolder.residentialAddress.houseNumber',
          }}
          onChange={onHouseNumberChange}
          dataTestIdPrefix="house-number"
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          name="policyHolder.residentialAddress.boxNumber"
          label={intl.formatMessage({
            id: 'fields.boxNumber.label',
          })}
          disabled={isHouseNumberDisabled}
          component={TextInputControl}
          {...fields.boxNumber}
        />
      </Grid>
    </Grid>
  );
};

ResidentialAddressFields.propTypes = {
  disabled: PropTypes.bool,
  isOffer: PropTypes.bool,
  isPostalCodeValid: PropTypes.bool,
  municipalities: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      id: PropTypes.number,
    })
  ),
  isFetchingMunicipalities: PropTypes.bool,
  selectedMunicipality: PropTypes.string,
  clearMunicipalities: PropTypes.func,
  fetchStreets: PropTypes.object,
  isMunicipalityDisabled: PropTypes.bool,
  isStreetNameDisabled: PropTypes.bool,
  isHouseNumberDisabled: PropTypes.bool,
  hasResidentialAddressSaveError: PropTypes.bool,
  hasResidentialAddressServerError: PropTypes.bool,
  clearResidentialAddressServerError: PropTypes.func,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  dispatch: PropTypes.func,
  change: PropTypes.func,
};

export default ResidentialAddressFields;
