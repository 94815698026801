import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { initialize } from 'redux-form';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';

import { history } from '../../history';
import { HANDLED_SERVER_ERRORS } from '../../shared/constants';
import {
  getPageByError,
  PROPOSAL_PAGES_TO_CONTAINER_IDS,
} from '../../shared/constants/requestErrors';
import { api, toastHandler } from '../../shared/services';
import { setGeneralError } from '../App/reducer';
import { fetchCompleteness } from '../NavigationPane/reducer';
import { fetchCalculationData } from '../PremiumBox/reducer';

import { fetchInsuredAdults, fetchInsuredChildren } from './Insured/reducer';
import { fetchPolicyHolder } from './PolicyHolder/reducer';
import {
  convertToOffer,
  fetchFormData,
  fetchMetadata,
  fetchPolicyPartyData,
  setServerErrors,
  submitProposal,
} from './reducer';

export const NOT_SUBMITTED_MESSAGE_ID = 'toasts.error.proposal.wasNotSubmitted';

export const resolveErrorMap = (skipErrorPredicate) => (errMap, errMsg) => {
  const page = getPageByError(errMsg);

  if (skipErrorPredicate && skipErrorPredicate(page)) {
    return errMap;
  }
  return set(errMap, errMsg, true);
};

export function* fetchMetadataSaga({ payload }) {
  try {
    const metadata = yield call(api.proposal.getMetadata, payload);
    yield put(fetchMetadata.success(metadata));
    return metadata;
  } catch (error) {
    yield put(fetchMetadata.failure(error));
  }
}

export function* convertToOfferSaga({ payload: { id, containerId } }) {
  try {
    const { offerId } = yield call(api.proposal.convertToOffer, id);
    yield put(convertToOffer.success());
    yield put(initialize(containerId));
    history.push(`/sales-process/offer/${offerId}`);
  } catch (error) {
    yield put(convertToOffer.failure(error));
    yield put(setGeneralError.action(error));
  }
}

export function* submitProposalErrorHandler(error) {
  yield put(submitProposal.failure(error));
  toastHandler.uniqueError({ id: NOT_SUBMITTED_MESSAGE_ID });

  const { status, ...restProps } = error;
  const errorKeys = Object.keys(restProps);

  if (isEmpty(errorKeys)) {
    yield put(setGeneralError.action(error));
    return;
  }

  for (const key of errorKeys) {
    if (HANDLED_SERVER_ERRORS.includes(key)) {
      const errorMap = error[key].reduce(resolveErrorMap(), {});
      const { firstInsuredAdult = {}, secondInsuredAdult = {}, ...errors } = errorMap;

      if (!isEmpty(firstInsuredAdult) || !isEmpty(secondInsuredAdult)) {
        errors.insuredAdults = [firstInsuredAdult, secondInsuredAdult];
      }

      yield put(setServerErrors.action({ [key]: errors }));
    } else {
      yield put(setGeneralError.action(error));
    }
  }
}

export const skipErrorPredicate = (containerIds) => (page) =>
  !containerIds.includes(PROPOSAL_PAGES_TO_CONTAINER_IDS[page]);

export function* submitProposalSaga(
  { payload: { id, form = null } },
  errorHandler = submitProposalErrorHandler
) {
  try {
    toastHandler.close(NOT_SUBMITTED_MESSAGE_ID);
    yield call(api.proposal.submitProposal, id);
    yield put(submitProposal.success());

    if (form) {
      yield put(initialize(form));
    }

    yield put(setServerErrors.clear());

    history.push(`/sales-process/proposals/${id}/documents`);
  } catch (error) {
    yield call(errorHandler, error, id);
  }
}

export function* handleProposalGeneralError(error) {
  if (error.status === 423) {
    history.push(history.location.pathname.replace(/[^/]+$/, 'needs-and-desires'));
  } else {
    yield put(setGeneralError.action(error));
  }
}

export function* fetchPolicyPartyDataSaga({ payload: id }) {
  yield all([
    put(fetchPolicyHolder.request({ id })),
    put(fetchInsuredAdults.request(id)),
    put(fetchInsuredChildren.request(id)),
  ]);
  const { error } = yield race({
    success: all([
      take(fetchPolicyHolder.SUCCESS),
      take(fetchInsuredAdults.SUCCESS),
      take(fetchInsuredChildren.SUCCESS),
    ]),
    error:
      take(fetchPolicyHolder.FAILURE) ||
      take(fetchInsuredAdults.FAILURE) ||
      take(fetchInsuredChildren.FAILURE),
  });
  if (error) {
    yield put(fetchPolicyPartyData.failure(error.payload));
    yield call(handleProposalGeneralError, error.payload);
    return;
  }
  yield put(fetchPolicyPartyData.success());
}

export function* clearPolicyPartyDataSaga() {
  yield put(fetchPolicyHolder.clear());
  yield put(fetchInsuredAdults.clear());
  yield put(fetchInsuredChildren.clear());
}

export function* fetchFormDataSaga({ payload: id }) {
  yield all([
    put(fetchCompleteness.request({ id })),
    put(fetchCalculationData.request({ id })),
    put(fetchMetadata.request(id)),
  ]);

  const { error } = yield race({
    success: all([
      take(fetchCompleteness.SUCCESS),
      take(fetchCalculationData.SUCCESS),
      take(fetchMetadata.SUCCESS),
    ]),
    error:
      take(fetchCompleteness.FAILURE) ||
      take(fetchCalculationData.FAILURE) ||
      take(fetchMetadata.FAILURE),
  });

  if (error) {
    yield put(fetchFormData.failure(error.payload));
    yield call(handleProposalGeneralError, error.payload);
    return;
  }
  yield put(fetchFormData.success());
}

export function* clearFormDataSaga() {
  yield put(fetchCompleteness.clear());
  yield put(fetchCalculationData.clear());
  yield put(fetchMetadata.clear());
}

export default function* proposalDetailsSagas() {
  yield all([
    takeLatest(fetchMetadata.REQUEST, fetchMetadataSaga),
    takeLatest(convertToOffer.REQUEST, convertToOfferSaga),
    takeLatest(submitProposal.REQUEST, submitProposalSaga),

    takeLatest(fetchPolicyPartyData.REQUEST, fetchPolicyPartyDataSaga),
    takeLatest(fetchPolicyPartyData.CLEAR, clearPolicyPartyDataSaga),
    takeLatest(fetchFormData.REQUEST, fetchFormDataSaga),
    takeLatest(fetchFormData.CLEAR, clearFormDataSaga),
  ]);
}
