import {FormHelperText, OutlinedInput, OutlinedInputProps} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {CleaveOptions} from 'cleave.js/options';
import React from 'react';
import MaskedInput from 'v2/common/components/TextField/MaskedInput';

export type TextFieldProps = OutlinedInputProps & {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  mask?: CleaveOptions;
  dataTestIdPrefix?: string;
};

export const TextField = React.forwardRef<any, TextFieldProps>(
  ({
     label,
     helperText,
     mask,
     error,
     name,
     inputProps,
     disabled,
     dataTestIdPrefix,
     ...restProps
   }, ref) => {
    return (
      <FormControl fullWidth size="small" error={error} margin="dense" disabled={disabled}>
        {label ? <FormLabel htmlFor={`${name}-id`}>{label}</FormLabel> : null}
        <OutlinedInput
          {...restProps}
          id={`${name}-id`}
          aria-describedby={`${name}-helper-text`}
          error={error}
          inputRef={ref}
          inputComponent={mask ? MaskedInput : undefined}
          inputProps={mask ? {
            ...inputProps,
            mask,
            'data-testid': `${dataTestIdPrefix}-input`
          } : {...inputProps, 'data-testid': `${dataTestIdPrefix}-input`}}
        />
        <FormHelperText id={`${name}-helper-text`} sx={{margin: 0}} disabled={false}>
          {helperText ?? <>&nbsp;</>}
        </FormHelperText>
      </FormControl>
    );
  }
);

export default TextField;
