import { FormattedMessage } from '../FormattedMessage';

import styles from './styles.module.scss';

// UPDATE ME WHEN THE DATES ARE CHANGED (see messages.json > maintenanceNotification.message)
const START_DATE_MAINTENANCE = '2024-10-31';
const END_DATE_MAINTENANCE = '2024-11-08';

const currentDate = new Date();

export const MaintenanceNotification = () => {
  if (
    currentDate <= new Date(END_DATE_MAINTENANCE) &&
    currentDate >= new Date(START_DATE_MAINTENANCE)
  ) {
    return (
      <div className={styles.MaintenanceNotification}>
        <FormattedMessage id="maintenanceNotification.message" />
      </div>
    );
  }
  return null;
};
