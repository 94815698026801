import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {PaymentFrequency} from 'v2/common/api/queries/premiumCalculation';
import ErrorMessage from 'v2/common/components/ErrorMessage';
import Label from 'v2/common/components/Label';
import NumberField from 'v2/common/components/NumberField';
import TextField from 'v2/common/components/TextField';
import {createDateMask, fiveDigitsMask, threeDigitsMask} from 'v2/common/utils/masks';
import {
  PremiumCalculationFormValues
} from 'v2/features/PremiumCalculation/components/PremiumCalculation/PremiumCalculation';
import * as fields from '../../../../../shared/constants/fields';

type InsuredPremiumFieldsProps = {
  index: number;
  isOneAdult: boolean;
  paymentFrequency: PaymentFrequency;
  control: Control<PremiumCalculationFormValues>;
  removeInsured: (index: number) => void;
};

const InsuredPremiumFields = ({
                                index,
                                isOneAdult,
                                paymentFrequency,
                                control,
                                removeInsured,
                              }: InsuredPremiumFieldsProps) => {
  const intl = useIntl();
  const isOneTimeOnlySelected = paymentFrequency === PaymentFrequency.OneTimeOnly;
  const birthDateMask = createDateMask(new Date().toISOString().split('T')[0]);

  const handleRemoveInsured = () => removeInsured(index);

  return (
    <>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
          <Typography variant="subtitle1">
            <FormattedMessage
              id={isOneAdult ? 'appDrawer.singleInsuredAdult' : 'appDrawer.insuredAdult'}
              values={{index: index + 1}}
            />
          </Typography>
          {!isOneAdult && (
            <IconButton size="small" color="primary" onClick={handleRemoveInsured}>
              <CloseIcon
                sx={{
                  fontSize: (theme) => theme.typography.subtitle1.fontSize,
                }}
              />
            </IconButton>
          )}
        </Box>
        <Controller
          name={`insuredAdults.${index}.benefit.amount`}
          control={control}
          render={({field, fieldState}) => (
            <NumberField
              {...field}
              autoFocus={index === 1}
              error={fieldState.invalid}
              label={
                <Label
                  label={<FormattedMessage id="fields.benefit.label"/>}
                  hint={
                    index === 0 && (
                      <FormattedMessage
                        id="fields.benefit.hint"
                        values={{min: fields.benefitLowerRangeValue}}
                      />
                    )
                  }
                />
              }
              startAdornment={<InputAdornment position="start">&euro;</InputAdornment>}
              mask={fiveDigitsMask}
              helperText={<ErrorMessage value={fieldState.error?.message}/>}
              dataTestIdPrefix="benefit"
            />
          )}
        />
        <Controller
          name={`insuredAdults.${index}.personalInformation.birthDate`}
          control={control}
          rules={{deps: `insuredAdults.${index}.payUntilAge`}}
          render={({field, fieldState}) => (
            <TextField
              {...field}
              error={fieldState.invalid}
              label={<FormattedMessage id="fields.birthDate.label"/>}
              placeholder={intl.formatMessage({
                id: 'fields.birthDate.placeholder',
              })}
              mask={birthDateMask}
              helperText={<ErrorMessage value={fieldState.error?.message}/>}
              dataTestIdPrefix="date-of-birth"
            />
          )}
        />
        <Controller
          name={`insuredAdults.${index}.payUntilAge`}
          control={control}
          render={({field, fieldState}) => (
            <NumberField
              {...field}
              error={fieldState.invalid}
              label={
                <Label
                  label={<FormattedMessage id="fields.payUntilAge.label"/>}
                  hint={
                    index === 0 && (
                      <>
                        <FormattedMessage id="fields.payUntilAge.hint.periodic"/>
                        <Box paddingTop="5px">
                          <FormattedMessage id="fields.payUntilAge.hint.oneTimeOnly"/>
                        </Box>
                      </>
                    )
                  }
                />
              }
              placeholder={intl.formatMessage({
                id: 'fields.payUntilAge.placeholder',
              })}
              mask={threeDigitsMask}
              disabled={isOneTimeOnlySelected}
              helperText={
                <PayUntilHelperText
                  errorMessage={fieldState.error?.message}
                  showInfoMessage={isOneTimeOnlySelected}
                />
              }
              dataTestIdPrefix="pay-until-age"
            />
          )}
        />
      </Grid>
    </>
  );
};

const PayUntilHelperText = ({
                              errorMessage,
                              showInfoMessage,
                            }: {
  errorMessage?: { id: string; values?: Record<string, any> } | string;
  showInfoMessage: boolean;
}) => {
  if (errorMessage) {
    return <ErrorMessage value={errorMessage}/>;
  }
  if (showInfoMessage) {
    return <FormattedMessage id="fields.payUntilAge.info"/>;
  }
  return <>&nbsp;</>;
};

export default InsuredPremiumFields;
